var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mb-2",attrs:{"text":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Regresar a reportes ")],1),_c('div',{staticClass:"card card-custom gutter-b"},[_vm._m(0),_c('div',{staticClass:"card-body px-1 px-sm-5"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-2 mb-5"},[_c('p',{staticClass:"text-h5 ma-0 pt-4 pl-4 font-weight-medium"},[_vm._v(" Parámetros ")]),_c('v-divider'),_c('v-container',{staticClass:"pa-4"},[_c('v-row',{staticClass:"align-items-center mb-5"},[_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":"Grado","outlined":"","loading":_vm.isSectionGroupLoading,"hide-details":"","items":_vm.grades,"item-text":"name","item-value":"id"},on:{"click":function($event){return _vm.resetFilters()},"change":function($event){_vm.loadAcademicGroups(), _vm.loadTechnicalGroups()}},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}}]),model:{value:(_vm.students.filters.grade_id),callback:function ($$v) {_vm.$set(_vm.students.filters, "grade_id", $$v)},expression:"students.filters.grade_id"}})],1)]),_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"outlined":"","hide-details":"","clearable":"","item-text":"grade_section_group_formatted","item-value":"id","loading":_vm.isSectionGroupLoading,"disabled":!_vm.students.filters.grade_id ||
                            !!_vm.students.filters.grade_speciality_group_id ||
                            _vm.isSectionGroupLoading,"items":_vm.academicGroups,"label":_vm.isSectionGroupLoading
                            ? 'Cargando grupos académicos...'
                            : 'Grupo académico'},on:{"change":_vm.loadStages},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.grade_section_group_formatted))])]):_vm._e()]}}]),model:{value:(_vm.students.filters.grade_section_group_id),callback:function ($$v) {_vm.$set(_vm.students.filters, "grade_section_group_id", $$v)},expression:"students.filters.grade_section_group_id"}})],1)]),_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":_vm.isSectionGroupLoading
                            ? 'Cargando grupos técnicos...'
                            : 'Grupo técnico',"loading":_vm.isSectionGroupLoading,"disabled":!_vm.students.filters.grade_id ||
                            !!_vm.students.filters.grade_section_group_id ||
                            _vm.isSectionGroupLoading,"outlined":"","hide-details":"","clearable":"","items":_vm.technicalGroups,"item-text":"grade_speciality_group_formatted","item-value":"id"},on:{"change":_vm.loadStages},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.grade_speciality_group_formatted))])]):_vm._e()]}}]),model:{value:(_vm.students.filters.grade_speciality_group_id),callback:function ($$v) {_vm.$set(_vm.students.filters, "grade_speciality_group_id", $$v)},expression:"students.filters.grade_speciality_group_id"}})],1)]),_c('v-col',{staticClass:"my-2 my-md-0",attrs:{"cols":"12","sm":"6","lg":"4","xl":"3"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('v-select',{attrs:{"label":_vm.isSectionGroupLoading
                            ? 'Cargando etapas...'
                            : 'Etapa',"loading":_vm.isSectionGroupLoading,"disabled":!_vm.canSelectStage,"outlined":"","hide-details":"","clearable":"","items":_vm.stages,"item-text":"stage.name","item-value":"stage_id"},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [(index === 0)?_c('v-chip',{attrs:{"color":"primary","small":""}},[_c('span',[_vm._v(_vm._s(item.stage.name))])]):_vm._e()]}}]),model:{value:(_vm.students.filters.stage_id),callback:function ($$v) {_vm.$set(_vm.students.filters, "stage_id", $$v)},expression:"students.filters.stage_id"}})],1)])],1)],1)],1),[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8","lg":"6","xl":"4"}},[_c('v-card',{attrs:{"outlined":"","disabled":!_vm.canGenerateReport}},[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('div',{staticClass:"text-overline mb-4"},[_vm._v(" estudiantes ")]),_c('v-list-item-title',{staticClass:"text-h5 mb-1"},[_vm._v(" Rendimiento académico ")]),_c('v-list-item-subtitle',{attrs:{"tag":"p"}},[_vm._v("Estudiantes agrupados por "),_c('strong',[_vm._v("grupo "+_vm._s(_vm.students.filters.stage_id == 8 ? "técnico" : "académico"))]),_vm._v(" en una etapa.")])],1),_c('v-list-item-avatar',{staticClass:"rounded",attrs:{"tile":"","icon":"","size":"80","color":"success"}},[_c('v-icon',{staticClass:"ml-n1",attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-microsoft-excel")])],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"outlined":"","rounded":"","text":"","href":_vm.getAcademicPermformanceReportUrl,"target":"_blank","download":""}},[_vm._v(" Generar reporte ")])],1)],1)],1)],1)]],2)],1)],1)],1)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header flex-nowrap border-0 pt-6 pb-0"},[_c('div',{staticClass:"card-title"},[_c('div',{staticClass:"card-label"},[_c('h3',[_vm._v(" Generación de reporte "),_c('span',{staticClass:"d-block text-muted pt-2 font-size-sm"},[_vm._v("Generación de reporte parametrizado sobre el rendimiento académico")])]),_c('p',{staticClass:"ma-0 pa-0 text-body-1"},[_c('strong',[_vm._v("Antes de poder generar el reporte, es nesario que seleccione almenos 3 parametros.")])]),_c('p',{staticClass:"ma-0 pa-0 text-body-1"},[_vm._v(" - La lista de "),_c('strong',[_vm._v("grupos académicos")]),_vm._v(" se cargará cuando haya elegido un grado. ")]),_c('p',{staticClass:"ma-0 pa-0 text-body-1"},[_vm._v(" - La lista de "),_c('strong',[_vm._v("grupos de especialidad")]),_vm._v(" se cargará cuando haya seleccionado un grado mayor a 9°. ")]),_c('p',{staticClass:"ma-0 pa-0 text-body-1"},[_vm._v(" - No se puede seleccionar un grupo académico y un grupo de especialidad al mismo tiempo. ")])])])])
}]

export { render, staticRenderFns }